<template>
  <div class="pa-12 d-flex justify-center flex-column align-center">
    <h1 class="mb-4">Esta página não exite.</h1>
    <v-btn depressed color="fmq_gray" dark outlined @click="$router.go(-1)">
      <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style></style>
